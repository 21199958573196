import React from "react"
import sanitizeHtml from "sanitize-html"

import { graphql } from "gatsby"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import HeroText from "../components/hero-text/hero-text"
import Container from "../components/container/container"
import Header from "../components/header/header"
import Content from "../components/content/content"
import Footer from "../components/footer/footer"
import PostContent from "../components/post-content/post-content"

export default function Document({ location, data }) {
  const DocumentTitle = data.markdownRemark.frontmatter.title
  const DocumentDescription = data.markdownRemark.frontmatter.description
  const DocumentHeroTitle = data.markdownRemark.frontmatter.heroTitle
  const DocumentContentWithVideo =
    data.markdownRemark.frontmatter.contentWithVideo
  const DocumentContentWithTable =
    data.markdownRemark.frontmatter.contentWithTable
  const DocumentShareImageUrl = data.markdownRemark.frontmatter.shareImageUrl

  const DocumentContent = DocumentContentWithVideo
    ? { __html: data.markdownRemark.html }
    : { __html: sanitizeHtml(data.markdownRemark.html) }

  return (
    <Wrapper>
      <SEO
        title={DocumentTitle}
        description={DocumentDescription}
        image={DocumentShareImageUrl}
      />
      <HeroText title={DocumentHeroTitle}>
        <Container>
          <Header classVariant="headerText" location={location} />
        </Container>
      </HeroText>
      <Content classVariant="contentPage">
        <Container>
          <PostContent
            classVariant={
              DocumentContentWithTable ? "postContentWithTable" : ""
            }
          >
            <div dangerouslySetInnerHTML={DocumentContent}></div>
          </PostContent>
        </Container>
      </Content>
      <Footer />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        heroTitle
        contentWithVideo
        contentWithTable
        shareImageUrl
      }
    }
  }
`
